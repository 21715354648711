const translations = {
    es: {
        common: {
            accept: 'Aceptar',
            cancel: 'Cancelar',
            save: 'Guardar',
            delete: 'Borrar',
            searchPlaceholder: 'Buscar...',
            loading: 'Cargando...',
            goBack: '< volver',
            components: {
                file: {
                    add: 'Subir archivo',
                    adj: 'Adjunto',
                },
                datepicker: {
                    placeholder: 'Insertar Fecha',
                },
            },
        },
        sidebar: {
            abmPatients: 'Pacientes',
            registerValidation: 'Validación de Registros',
            registration: 'Registración',
            transactions: 'Transacciones',
            bonifications: 'Bonificaciones',
            audit: 'Auditoria',
            registrarProfile: 'Perfil',
            nexMonitor: 'Monitor Nex',
            grantingMonitor: 'Monitor de Grants',
            indications: 'Indicaciones',
            contracts: 'Contratos',
            auditorTransactions: 'Transacciones Auditor',
            transactionMonitor: 'Monitor Interface SAP',
            sharedRisk: 'Riesgo Compartido ',
            help: 'Ayuda',
            fmi_profile: 'Pacientes',
            //WALLET
            wallet: 'Billetera',
            monetaryBase: 'Base monetaria',
            shippingAddress: 'Enviar Nex',
        },
        header: {
            firstName: 'Nombre',
            lastName: 'Apellido',
        },
        login: {
            // old translations user on recovery password screen
            badCredentials: 'Combinación de usuario y clave incorrecta. Intente nuevamente.',
            emailPlaceholder: 'Email',
            enterEmail: 'Ingrese el mail con el que está registrado para recibir el link',
            recoverPasswordError:
                'Se produjo un error al recuperar la clave de acceso. Verifique que el email ingresado corresponda a un usuario del sistema',
            recoverPasswordSuccess: 'El link fue enviado correctamente',
            // new translations
            loginYourAccount: 'Iniciar sesión',
            user: 'Usuario',
            password: 'Contraseña',
            enterYourUser: 'Ingresa tu usuario',
            enterYourPassword: 'Ingresa tu clave de acceso',
            iForgotMyPassword: 'Olvide mi clave de acceso',
            register: 'Registrarse',
            enter: 'Ingresar',
            powerBy: '© 2020 Nexus powered by Roche',
            enterAPassword: 'Ingrese una clave de acceso',
            enterAUser: 'Ingrese un usuario',
        },
        recoverPassword: {
            forgotYourPassword: '¿Olvidaste tu clave de acceso?',
            enterYourEmail: 'Ingresa tu email',
            recoverPassword: 'Recuperar clave de acceso',
            backToTop: 'volver al incio',
            instructionsSent: '¡Instrucciones enviadas!',
            instructionsParagraph1:
                'Las instrucciones para restablecer la clave de acceso se han enviado a %{email}.',
            instructionsParagraph2:
                'Recibirás este email dentro de los 5 minutos. \n' +
                'Asegúrate también de revisar tu carpeta de spam.',
            errors: {
                enterAnEmail: 'Ingrese un email',
                enterAValidEmail: 'Ingrese un email valido',
            },
        },
        resetPassword: {
            resetYourPassword: 'Resetea tu clave de acceso',
            enterYourNewPassword: 'Ingrese su nueva clave de acceso',
            confirmYourNewPassword: 'Confirme su nueva clave de acceso',
            resetPassword: 'Resetear clave de acceso',
            backToTop: 'volver al incio',
            passwordReset: 'Contraseña reseteada',
            yourPasswordHasBeenSuccessfullyReset: 'Su clave de acceso fue reseteada exitosamente',
            errors: {
                enterAPassword: 'Ingrese una nueva clave de acceso',
                confirmThePassword: 'Confirme su nueva clave de acceso',
                passwordMustMatch: 'Las contraseñas deben ser iguales',
                passwordMinLength: 'La clave de acceso debe tener al menos 8 caracteres',
            },
        },
        profile: {
            user: 'Usuario',
            name: 'Nombre',
            phone: 'Teléfono',
            email: 'Correo Electrónico',
            confirm: 'Confirmar',
            updateUserSuccess: '',
            points: {
                courses: 'Puntuación por Cursos',
                feedbacks: 'Puntuación por Feedbacks',
                evaluations: 'Puntuación por Evaluaciones',
            },
            linkedin: 'Linkedin',
            company: 'Empresa',
            position: 'Cargo',
            tooltip: {
                welcomeMessage: 'Hola, %{firstName} %{lastName}!',
                exitFromPlatform: 'Salir de la plataforma',
                goToProfile: 'Ver perfil',
            },
            card: {
                changePassword: 'Cambiar clave de acceso',
            },
            rocheUserKeyActive: 'Clave de billetera activa',
            configurationCard: {
                keyActive: 'Clave de encriptación activa',
                title: 'Configuración del perfil',
                subtitle: 'Necesitamos información para completar el perfil',
                inputLabels: {
                    os: 'Obra Social',
                    auditGroup: 'Grupo Auditor',
                    user: 'Usuario',
                    name: 'Nombre',
                    lastName: 'Apellido',
                    email: 'Email',
                    phone: 'Teléfono',
                },
                createPrivateKey: 'Crear clave de encriptación',
                recoverPrivateKey: 'Importar clave de encriptación',
                showKey: 'Mostrar clave de encriptación',
            },
            noPhotoModal: {
                title: 'Las capturas de pantalla no son seguras',
                bodyPart1:
                    'Si tomas una captura de pantalla, otras aplicaciones podrían ver tu copia de seguridad. La forma',
                boldWord1: 'mas acertada',
                bodyPart2: 'es resguardada',
                boldWord2: 'con tinta y papel',
                button: 'Entendido',
            },
            recoverPrivateKeyModal: {
                title: 'Visualizar clave de encriptación',
                body: 'Para visualizar su clave de encriptación es necesario que ingrese su clave de acceso Nexus.',
                button: 'Confirmar',
                thisIsYourEncryptionKey: 'Esta es su clave de encriptación',
            },
            createPrivateKeyWizard: {
                stepsName: {
                    init: 'Inicio',
                    key: 'Clave',
                    end: 'Fin',
                },
                firstStep: {
                    clarification:
                        'Antes de comenzar a crear tu clave de encriptación es importante tener a mano tinta y papel.',
                    continue: 'continuar',
                },
                secondStepA: {
                    title: 'La frase fue creada aleatoriamente por este dispositivo, anótala cuidadosamente.',
                    subtitleFirstPart: 'Esta clave es privada y ',
                    willNotBeStorageByNexus: 'no será almacenada por nexus.',
                    subtitleSecondPart:
                        ' En caso de perdida no se puede ' +
                        'recuperar por lo tanto es tu responsabilidad resguardarla.',
                    button: 'ya he anotado',
                    goBack: 'volver',
                },
                secondStepB: {
                    title: 'Verificación',
                    subtitle: 'Ordena correctamente tus 12 palabras.',
                    button: 'verificar',
                },
                secondStepC: {
                    successful: {
                        title: 'Tu clave de encriptación ha sido creada correctamente',
                        button: 'continuar',
                    },
                    error: {
                        title: 'Hubo un error al ingresar tu clave de encriptación. Intenta nuevamente. Muchas gracias!',
                        button: 'volver',
                    },
                },
            },
            importPrivateKeyWizard: {
                secondStepA: {
                    title: 'Escribí las 12 palabras de tu clave de encriptación.',
                    button: 'Verificar',
                    goBack: 'Volver',
                },
                secondStepAPK: {
                    title: 'Ingresá tu clave privada.',
                    inputPlaceholder: 'Pegar desde email',
                    button: 'Verificar',
                    goBack: 'Volver',
                },
                secondStepB: {
                    successful: {
                        title: 'Su clave de encriptación ha sido ingresada correctamente',
                        button: 'Continuar',
                    },
                    error: {
                        title: 'Hubo un error al ingresar tu clave de encriptación. Intenta nuevamente. Muchas gracias!',
                        button: 'Volver',
                    },
                },
            },
            changePasswordModal: {
                title: '¿Quieres cambiar tu clave de acceso?',
                currentPassword: 'Ingresa tu clave de acceso actual',
                newPassword: 'Ingresa tu nueva clave de acceso',
                repeatNewPassword: 'Repite tu nueva clave de acceso',
                confirm: 'Confirmar',
                errors: {
                    currentPasswordIsRequired: 'La clave de accesso actual es requerida',
                    newPasswordIsRequired: 'La nueva clave de accesso es requerida',
                    repeatNewPasswordIsRequired: 'Debe repetir su nueva clave de accesso',
                    passwordsNotMatch: 'Las claves de accesso no coiciden',
                    passwordMinLength: 'La clave de acceso debe tener al menos 8 caracteres',
                },
            },
        },
        patient: {
            title: 'Pacientes',
            search: 'Buscar...',
            createNew: 'Crear Nuevo',
            statusFilter: {
                active: 'Activo',
                inactive: 'Inactivo',
            },
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} pacientes',
                next: 'Siguiente',
                page: 'Página',
                pagedMessageEmpty: 'Mostrando 0 de 0 pacientes',
                noRows: 'No hay pacientes',
                headers: {
                    id: 'ID paciente',
                    dni: 'DNI',
                    name: 'Nombre',
                    affiliate: 'Afiliado',
                    contracts: 'Contratos',
                    status: 'Estado',
                    action: 'Acción',
                },
                status: {
                    active: 'Activo',
                    inactive: 'Inactivo',
                },
                buttonTooltips: {
                    editInformation: 'Editar Información',
                    seeInformation: 'Ver información',
                    activate: 'Activar',
                    deactivate: 'Desactivar',
                    msgCycle: '¡Click aquí para ver las dosis programadas!',
                },
            },
            newPatient: {
                title: 'Ingresar Nuevo Paciente',
                labels: {
                    dni: 'DNI',
                    nameAndLastName: 'Nombre y Apellido',
                    affiliate: 'Afiliado',
                    contracts: 'Contratos',
                },
                contractsSelection: {
                    allItemsAreSelected: 'Todos los contratos están seleccionados.',
                    clearSearch: 'Borrar búsqueda',
                    noOptions: 'No hay opciones',
                    search: 'Buscar',
                    selectAll: 'Seleccionar todo',
                    selectSomeItems: 'Seleccionar...',
                },
                confirm: 'Confirmar',
                cancel: 'Cancelar',
                patientCreatedSuccessfully: 'Se ha creado exitosamente un nuevo paciente',
                creationCanceled: 'No se ha podido registar el nuevo paciente',
                error: 'Los siguientes campos son requeridos: ',
                duplicateDni: 'El DNI que ha ingresado pertenece a un paciente existente',
                duplicateAffiliate:
                    'El número de afiliado que ha ingresado pertenece a un paciente existente',
            },
            editPatient: {
                title: 'Datos Paciente',
                patientEditedSuccessfully: 'Se ha editado exitosamente el paciente',
                editionCanceled: 'No se ha podido editar el nuevo paciente',

                patientCard: {
                    title: 'Paciente',
                    id: 'ID',
                    dni: 'DNI',
                    nya: 'Nombre y Apellido',
                    affiliate: 'Afiliado',
                    sex: 'Sexo',
                    sexes: {
                        N: 'No especificado',
                        M: 'Masculino',
                        F: 'Femenino',
                    },
                    birthDate: 'Fecha de Nacimiento',
                    weight: 'Peso',
                    date: 'Fecha',
                    addWeight: 'Agregar Peso',
                    actDeact: 'Activa/Desactivado',
                    active: 'Activo',
                    inactive: 'Inactivo',
                    access: 'Accesos',
                },

                contractCard: {
                    title: 'Contratos',
                    id: 'ID',
                    desc: 'Descripción',
                    type: 'Tipo Contrato',
                    subtype: 'Subtipo',
                    evReq: 'Evidencia Requerida',
                    progDose: 'Dosis Programadas',
                    act_inact: 'Activa / Inactiva',
                    addNew: 'agregar nuevo',
                },
            },
            evidence: {
                title: 'Evidencias',
                patient: {
                    patient: 'Paciente',
                    id: 'ID Paciente',
                    nya: 'Nombre y Apellido',
                    dni: 'DNI',
                    affiliate: 'Afiliado',
                    sex: 'Sexo',
                    birthDate: 'Fecha de Nac',
                    weight: 'Peso',
                },
            },
            migration: {
                modal: {
                    title: 'Migración de pacientes',
                    text: 'La migración de pacientes esta en proceso, por favor aguarde unos minutos',
                    error: {
                        button: 'Logout',
                        text: 'Un error a ocurrido durante la migración. Por favor intente cerrar la sesión y volver a logearse',
                    },
                },
            },
        },
        newPatient: {
            title: 'Ingresar Nuevo Paciente',
            labels: {
                dni: 'DNI',
                name: 'Name',
                affiliate: 'Afiliado',
                contract: 'Contrato',
            },
        },
        editPatient: {
            title: 'Datos Paciente',
            brcm: {
                title: 'Patient',
                evidences: 'Evidencias',
                programedDoses: 'Dosis Programadas',
            },
            evidences: {
                cycle: 'Ciclo',
                currentCycle: 'Ciclo Actual',
                desc: 'Descripción',
                details: 'Detalle',
                evReq: 'Evidencia Requerida',
                adj: 'Adjunto',
                action: 'Acción',

                addNewEvidence: 'Agregar nueva evidencia',
                addEvidence: 'Agregar Evidencia',
                files: {
                    sizeExceeded: {
                        title: 'Tamaño excedido',
                        text: 'El archivo no puede superar un tamaño de 20MB',
                    },
                    fileAllowed: {
                        title: 'Tipo de archivo no válido',
                        text: 'El tipo de archivo seleccionado no es válido. Por favor, elige un archivo con una de las siguientes extensiones: %{extensions}',
                    },
                },
            },
            doses: {
                doses: 'Dosis Programadas',
                mgReg: 'Mg Registrados',
                dateOf: 'Fecha de',
                registration: 'Registracion',
                var: 'Var',
                cycle: 'Ciclo',
                day: 'Dia',
                estDate: 'Fecha Estimada',
                mgTotal: 'Total mg',
            },
        },
        dashboard: {
            welcomeCard: {
                title: 'Bienvenido/a a Nexus!',
                paragraph:
                    ' Lorem Ipsum passages, and more recently\n' +
                    'with desktop publishing software like Aldus\n' +
                    'PageMaker including versions Lorem Ipsum.',
                button: 'mas info',
            },
            newsCard: {
                title: 'Recursos',
            },
            docsButtons: {
                osTutorial: 'Tutorial Obra Social',
                drogTutorial: 'Tutorial Droguería',
            },
            screenQuickAccessCard: {
                button: 'Acceder',
                abmPatients: 'Pacientes',
                transactions: 'Transacciones',
            },
        },
        registration: {
            title: 'Registración',
            downloadHistory: 'Descargar Historial',
            inputLabels: {
                dni: 'DNI',
                os: 'Obra Social',
                name: 'Nombre y Apellido',
                placeholder: {
                    name: 'Usuario no encontrado',
                    os: 'Usuario no encontrado',
                },
            },
            continue: 'Continuar',
            add: 'Agregar',
            validate: 'Validar',
            scanTheProductQr: 'Escanea el código QR del producto',
            scanner: 'Pistola',
            keyboard: 'Teclado',
            deleteModalTitle: '¿Estas seguro de eliminar la traza?',
            confirmSaveModalTitle: '¿Confirma guardar todos los productos escaneados?',
            productCard: {
                description: 'Descripción',
                gtin: 'GTIN (01)*',
                sn: 'SN (21)*',
                ven: 'VEN',
                lot: 'LOT (10)',
                selectAProduct: 'Seleccione un producto',
                cycle: 'CICLO',
            },
            header: {
                reg: 'Registración',
                reg_as: 'Registración/ Asignación de ciclo',
            },
            snInputPlaceHolder: 'Escriba los 14 dígitos',
            errors: {
                invalidDate: 'La fecha de vencimiento ingresada no es válida',
            },
        },
        transaction: {
            title: 'Transacciones',
            auditorTitle: 'Transacciones auditor',
            sharedRiskTitle: 'Auditoria Riesgo Compartido',
            transactionToAuditTitle: 'Transacciones a auditar',
            validate: 'Validar',
            reject: 'Rechazar',
            download: 'Descargar',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} transacciones',
                pagedMessageEmpty: 'Mostrando 0 de 0 transacciones',
                noRows: 'No hay transacciones',
                headers: {
                    id: 'ID',
                    dni: 'DNI',
                    name: 'Nombre',
                    affiliate: 'Afiliado',
                    contracts: 'Contrato',
                    product: 'Producto',
                    date: 'Fecha Estado',
                    registrar: 'Registrador',
                    cycle: 'Ciclo',
                    tokenAmount: 'Importe Token',
                    currency: 'Moneda',
                    paymentOrder: 'Orden de Pago',
                    status: 'Estado',
                    action: 'Acción',
                    edit: 'Editar',
                    os: 'Obra Social',
                    amount: ' Importe Calculado',
                    auditorComment: ' Comentario Auditor',
                    reviewEndContract: 'Revisar finalización de Contrato',
                    review: 'Revisar',
                    patientId: 'ID Paciente',
                    dateAuditor: 'Fecha',
                    incident: 'Incidente',
                    detail: 'Detalle',
                    number: 'Número',
                    auditorGroup: 'Grupo de Auditores',
                    viewDetails: 'Ver Detalles',
                },
                status: {
                    validated: 'Validado',
                    pending: 'Pendiente',
                    processingPayment: 'Procesando Pago',
                    errorSAP: 'Error interface SAP',
                    rejected: 'Rechazado',
                    approved: 'Aprobado',
                    auditRejected: 'Rechazo Auditoría',
                    auditPending: 'Pendiente Auditoría',
                    auditApproved: 'Aprobado Auditoría',
                    auditRequired: 'Requiere Auditoría',
                    noAuditor: 'Sin Auditor Asignado',
                    auditConsumptionRejected: 'Consumo Rechazado',
                    cleanedEvidence: 'Evidencia Borrada',
                    definition: 'Definición de estado',
                    paid: 'Pagado',
                    posted: 'Finalizado',
                    validationError: 'Error de validación',
                    paymentError: 'Error al pagar',
                    endSuccessfulTreatment: 'Fin Tratamiento Exitoso',
                    endTreatmentProgression: 'Fin Tratamiento Progresion',
                    all: 'Todos',
                },
            },
            detailModal: {
                colorbar: {
                    patient: 'Paciente',
                    dni: 'DNI',
                    nro_afiliado: 'Nro de Afiliado',
                },
                header: {
                    contract: 'Contrato:',
                    contract_type: 'Tipo de contrato',
                    subtype: 'Subtipo',
                    cicle: 'Ciclo',
                },
                auditGroup: {
                    comment_history: 'Historial de comentarios',
                    auditGroup: 'Grupo de Auditores',
                    report_button: 'Reporte completo',
                },
                commentSection: {
                    add_comment: 'Agregar comentarios',
                    comment: 'Comentarios',
                    audit_rejected: 'Auditoria rechazada',
                    cleanedEvidence: 'Evidencia borrada',
                    addcomment_button: '+ Agregar comentario',
                },
                footer: {
                    sharebutton: 'Compartir datos del paciente',
                    back: 'Volver',
                    resend: 'Reenviar a auditar',
                },
            },
            successfulModal: {
                translationsValidated: 'Las transacciones han sido validadas correctamente',
                translationsRejected: 'Las transacciones han sido rechazadas correctamente',
                button: 'Continuar',
            },
            errorModal: {
                validation: {
                    title: 'Resultados de la validación',
                    transactionsValidatedSuccessfully:
                        'Transacciones validadas correctamente (%{successTotal})',
                    thisErrorHaveAppeared: 'Han aparecido los siguientes errores (%{errorTotal})',
                },
                rejection: {
                    title: 'Resultados del rechazo',
                    transactionsRejectedSuccessfully:
                        'Transacciones rechazadas correctamente (%{successTotal})',
                    thisErrorHaveAppeared: 'Han aparecido los siguientes errores (%{errorTotal})',
                },
            },
            confirmRejectionModal: {
                text: '¿Está seguro de querer rechazar?',
            },
        },
        indication: {
            title: 'Indicaciones',
            search: 'Buscar...',
            createNew: 'Crear Nueva',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} indicaciones',
                next: 'Siguiente',
                page: 'Página',
                pagedMessageEmpty: 'Mostrando 0 de 0 indicaciones',
                noRows: 'No hay indicaciones',
                headers: {
                    id: 'ID',
                    name: 'Nombre',
                    type: 'Tipo',
                    desc: 'Descripción',
                    duration: 'Duración',
                    action: 'Acción',
                },
                buttonTooltips: {
                    edit: 'Editar',
                    delete: 'Eliminar',
                },
            },
            details: {
                indication: {
                    title: 'Indicaciones',
                    indications: 'Indicación',
                    type: 'Tipo de Tratamiento',
                    duration: 'Duracion de Tratamiento',
                    description: 'Descripción',
                    treatmentTypes: {
                        C: 'Cronico',
                        P: 'Hasta Progresion',
                        F: 'Finito',
                    },
                    durationTypes: {
                        C: 'Ciclos',
                        T: 'Tomas',
                        A: 'Aplicaciones',
                    },
                },
                products: {
                    title: 'Productos',
                    subtitle: 'Esquema de Tratamiento',
                    addNew: '+ Agregar producto',
                    table: {
                        aplication: 'Aplicacion',
                        aplicationTime: 'Desde | Hasta',
                        family: 'Familia de Producto',
                        fromDay: 'Desde Dia',
                        frequency: 'Frecuencia',
                        type: 'Tipo de Dosis',
                        dose: 'Dosificacion',
                        maxDose: 'Dosis Maxima',
                        action: 'Accion',
                    },
                    doseType: {
                        F: 'Fija',
                        P: 'Por peso',
                    },
                },
                evidences: {
                    title: 'Evidencias',
                    addNew: '+ Agregar Evidencia',
                    table: {
                        number: 'Numero',
                        description: 'Descripcion',
                        detail: 'Detalle',
                        cycle: 'Ciclo',
                        action: 'Accion',
                    },
                    types: {
                        0: '-',
                        1: 'Tomografia',
                        2: 'Historia Clinica',
                        3: 'Receta Medica',
                    },
                },
            },
        },
        contract: {
            title: 'Contratos',
            search: 'Buscar...',
            createNew: 'Crear Nuevo',
            inputLabels: {
                name: 'Nombre',
                contractType: 'Tipo de Contrato',
                os: 'Obra Social',
                indications: 'Indicaciones',
            },
            cardHeaders: {
                titleContract: 'Contrato',
                titleNumber: 'Número',
                titleAuditor: 'Auditoria',
                titleBenefits: 'Beneficios',
                titleEvidences: 'Evidencias',
            },
            versionDetail: {
                title: 'Versión',
                dateRangePicker: {
                    startDatePickerPlaceholder: 'Vigencia Desde',
                    endPatientDischargePlaceholder: 'Fin de alta de pacientes',
                    endRegistrationPlaceholder: 'Fin de registracion',
                    clearDatesSelectionButtonTooltip: 'Borrar selección de fechas',
                },
                status: {
                    A: 'Activo',
                    B: 'Inactivo',
                    F: 'Finalizado',
                    C: 'Cerrado',
                },
                benefitCard: {
                    title: 'Beneficios',
                    header: {
                        sinceTo: 'Desde | Hasta',
                        productFamily: 'Familia de\n' + 'Producto',
                        presentation: 'Presentación',
                        benefitType: ' Tipo de\n' + 'Beneficio',
                        referenceProductFamily: 'Familia de\n' + ' Referencia',
                        referenceProductPresentation: 'Presentacion de\n' + ' Referencia',
                        referenceProduct: 'Producto de\n' + ' Referencia',
                        benefit: 'Beneficio',
                        fech: 'Fecha',
                        calculator: 'Operador calculador',
                        calculation: 'Cálculo',
                        action: 'Acción',
                    },
                    addApplication: '+ Agregar Aplicación',
                    riskSharingCalculation: 'Cálculo de riesgo compartido',
                },
                inputLabels: {
                    patientsMax: 'Max Pacientes',
                    activePatients: 'Pacientes Activos',
                    status: 'Estado',
                    controlPeriodFrequency1: 'Frecuencia',
                    controlPeriodFrequency2: 'Periodo control',
                    controlPeriodFrequency3: 'Aplicaciones incluye carga',
                    controlPeriodTolerance1: 'Tolerancia',
                    controlPeriodTolerance2: 'Periodo de control',
                    controlPeriodTolerance3: 'Periodo por dias',
                    doseToleranceMg1: 'Tolerancia',
                    doseToleranceMg2: 'dosis mg',
                    doseToleranceMg3: '% Sobre el acumulado',
                },
                audit: {
                    title: 'Auditoria',
                    group: 'Grupo de Auditores',
                    shareinfo: 'Informacion a compartir con el Auditor',
                    selectedFilter: {
                        nameSurname: 'Nombre y Apellido',
                        dni: 'DNI',
                        afiliateNumber: 'Número de Afiliado',
                    },
                },
            },
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} contratos',
                next: 'Siguiente',
                page: 'Página',
                pagedMessageEmpty: 'Mostrando 0 de 0 contratos',
                noRows: 'No hay contratos',
                headers: {
                    id: 'ID',
                    name: 'Nombre',
                    os: 'Obra Social',
                    type: 'Tipo de Contrato',
                    indication: 'Indicacion',
                    status: 'Estado',
                    action: 'Acción',
                    description: 'Descripcion',
                },
                buttonTooltips: {
                    edit: 'Editar',
                    delete: 'Borrar',
                },
            },
            buttons: {
                save: 'Guardar Cambios',
                new: 'Guardar Nuevo',
            },
            type: {
                S: 'SUR',
                I: 'INDICACION',
            },
        },
        registrarSignUp: {
            signUpForm: {
                title: 'Crear una nueva cuenta',
                inputPlaceholders: {
                    name: 'Nombre',
                    lastName: 'Apellido',
                    user: 'Usuario',
                    email: 'Email',
                    password: 'Clave de acceso',
                    repeatPassword: 'Repetir clave de acceso',
                },
                button: 'Continuar',
                validationErrors: {
                    nameRequired: 'El nombre es requerido',
                    lastNameRequired: 'El apellido es requerido',
                    userRequired: 'El usuario es requerido',
                    emailRequired: 'El email es requerido',
                    passwordRequired: 'La clave de acceso es requerida',
                    repeatPasswordRequired: 'Por favor repita la clave de acceso',
                    emailInvalid: 'El email es invalido',
                    passwordsDontMatch: 'Las claves de acceso no coinciden',
                },
            },
            accountCreatedSuccessful: {
                title: '¡Activa tu cuenta!',
                paragraph1: 'Se ha enviado a %{email} para activar tu cuenta.',
                paragraph2:
                    'Recibirás este email dentro de los 5 minutos. Asegúrate también de revisar tu carpeta de spam.',
                backToTop: 'volver al incio',
            },
        },
        registrarNewAccount: {
            yourAccount: {
                welcomeYou: 'Bienvenido, %{name}',
                subtitle: 'Por favor ingresa los datos de la institución.',
                typeOfInstitution: 'Tipo de Institución',
                institutionOptions: {
                    takeCareOrPatient: 'Cuidador/Paciente',
                    pharmacy: 'Farmacia',
                    infusionCenters: 'Centro de infusion',
                    others: 'Otros',
                },
                institutionName: 'Nombre de la Institución',
                cuit: 'CUIT',
                button: 'Continuar',
                validationErrors: {
                    institutionTypeIsRequired: 'El tipo de institución es requerido',
                    institutionNameIsRequired: 'El nombre de la institución es requerido',
                    cuitIsRequired: 'El cuit es requerido',
                    addressIsRequired: 'La dirección es requerida',
                    phoneIsRequired: 'El teléfono es requerido',
                    cuitNonExistent:
                        'El CUIT ingresado no existe, por favor ingresa datos de institucion y sucursal',
                },
                address: 'Dirección',
                phone: 'Teléfono',
                yourBranch: 'Tu sucursal',
                newBranch: 'Nueva sucursal',
                logout: 'Cerrar Sesión',
            },
            selectBranchModal: {
                title: 'Selecciona tu sucursal',
                subtitle: 'Este cuit existe, por favor selecciona tu sucursal',
                noBranchesFound: 'No se encontró ninguna sucursal',
                button: 'Nueva sucursal',
                branch: 'Sucursal %{id}',
            },
            successAccountCreationModal: {
                branchCrated:
                    'Tu sucursal fue creada correctamente. Siguiente paso, crear tu clave de encriptación.',
                branchSelected:
                    'La sucursal fue seleccionada correctamente. Siguiente paso, importar tu clave de encriptación.',
                branchAndInstitutionCreated:
                    'La institución y la sucursal fueron creadas correctamente. ' +
                    'Siguiente paso, crear tu clave de encriptación.',
                button: 'Continuar',
            },
            accountActivation: {
                yourAccountWasActivatedCorrectly: 'Su cuenta se ha activado exitosamente!',
                anErrorHasOccur: 'Ha ocurrido un error al activar su cuenta',
                goToBack: 'Ir al inicio',
            },
        },
        registrarProfile: {
            institutionCard: {
                title: 'Institución',
                name: 'Nombre',
                cuit: 'CUIT',
                typeOfInstitution: 'Tipo de Institución',
                phone: 'Teléfono',
                branchAddress: 'Dirección de la sucursal',
            },
            registrarCard: {
                title: 'Registrador',
                name: 'Nombre',
                lastName: 'Apellido',
                user: 'Usuario',
                email: 'Email',
                password: 'Clave de acceso',
                repeatPassword: 'Repetir tu clave acceso',
                button: 'Guardar',
                errors: {
                    nameIsRequired: 'El campo nombre es requerido',
                    lastNameIsRequired: 'El campo apellido es requerido',
                    emailIsRequired: 'El campo email es requerido',
                    emailNotValid: 'El campo email no es válido',
                    passwordIsRequired: 'El campo clave de accesso es requerido',
                    repeatPasswordIsRequired: 'Debe repetir su clave de accesso',
                    passwordsNotMatch: 'Las claves de accesso no coiciden',
                    passwordMinLength: 'La clave de acceso debe tener al menos 8 caracteres',
                },
            },
        },
        rocheTransaction: {
            title: 'Reporte Transacciones',
            dateRangePicker: {
                startDatePickerPlaceholder: 'Desde',
                endDatePickerPlaceholder: 'Hasta',
                clearDatesSelectionButtonTooltip: 'Borrar selección de fechas',
            },
            downloadOptions: {
                transactions: 'Transacciones',
                tokensEmitted: 'Tokens enviados',
                tokensReceived: 'Tokens recibidos',
            },
            balance: 'Balance',
            pay: 'Pagar',
            payDisabledTooltip: 'Clave de encriptación no importada',
            payDisabledNoBalanceToolTip: 'Balance no disponible',
            walletLoadErrorAlert: {
                title: 'Error',
                body: 'Ha ocurrido un error al intentar cargar la billetera',
            },
            walletTransactionErrorsAlert: {
                title: 'Error',
                body: 'No se ha podido procesar la transacción seleccionada',
            },
            walletTransactionInvalidAddressAlert: {
                title: 'Error',
                body: 'La dirección de la billetera de %{os} es inválida',
            },
            walletTransactionAmountExceededAlert: {
                title: 'Error',
                body: 'La suma de las transacciones a pagar supera el balance de NEX',
            },
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} transacciones',
                pagedMessageEmpty: 'Mostrando 0 de 0 transacciones',
                noRows: 'No hay transacciones',
                headers: {
                    id: 'ID',
                    patientId: 'ID del Paciente',
                    os: 'Obra Social',
                    contracts: 'Contrato',
                    product: 'Producto',
                    date: 'Fecha',
                    registrar: 'Registrador',
                    paymentOrder: 'Orden Pago',
                    tokenAmount: 'Importe Token',
                    status: 'Estado',
                    pay: 'Pagar',
                },
                status: {
                    validated: 'Validado',
                    pending: 'Pendiente',
                    processingPayment: 'Procesando Pago',
                    rejected: 'Rechazado',
                    paid: 'Pagado',
                    posted: 'Finalizado',
                    validationError: 'Error de validación',
                    errorSAP: 'Error interface SAP',
                    all: 'Todos',
                },
                ungrantedAddress: {
                    title: 'Address sin grantear',
                },
            },
            validatePaymentsErrorAlert: {
                title: 'Error',
            },
            transactionsSuccessfulModal: {
                transactionsPayed: 'Las transacciones seleccionadas han sido pagadas correctamente',
                button: 'Continuar',
            },
            transactionSuccessfulModal: {
                transactionPayed: 'La transacción seleccionada ha sido pagada correctamente',
                button: 'Continuar',
            },
            errorModal: {
                title: 'Resultados del pago',
                transactionsPayedSuccessfully:
                    'Transacciones pagadas correctamente (%{successTotal})',
                thisErrorHaveAppeared: 'Han aparecido los siguientes errores (%{errorTotal})',
            },
            confirmPaymentModal: {
                text: '¿Está seguro de querer pagar?',
            },
        },
        nexMonitor: {
            title: 'Monitor NEX',
            download: 'Descargar',
            sendDataToSAP: 'Enviar datos a SAP',
            sendDataToSAPDisabledTooltip: 'Clave de encriptación no importada',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} transacciones',
                pagedMessageEmpty: 'Mostrando 0 de 0 transacciones',
                noRows: 'No hay transacciones',
                headers: {
                    id: 'ID',
                    tokenAmount: 'Importe Token',
                    origin: 'Origen',
                    destination: 'Destino',
                    timeReceived: 'Fecha',
                    status: 'Estado',
                    sendDataToSAP: 'Enviar datos a SAP',
                },
                status: {
                    all: 'Todos',
                    RR: 'Recibido en Address Roche',
                    ER: 'Enviado desde Address Roche',
                    AE: 'Address Externa',
                    CR: 'Contabilizado por Roche',
                    FI: 'Fallo Interfaz',
                    ES: 'Enviado a SAP',
                },
            },
            successfulModal: {
                transactionsSentToSAP: 'Las transacciones han sido enviadas a SAP correctamente',
                button: 'Continuar',
            },
            errorModal: {
                title: 'Resultados del envío a SAP',
                transactionsSentToSAPSuccessfully:
                    'Transacciones enviadas correctamente (%{successTotal})',
                thisErrorHaveAppeared: 'Han aparecido los siguientes errores (%{errorTotal})',
            },
            confirmSentToSAPModal: {
                text: '¿Está seguro de querer enviar los datos a SAP?',
            },
            sendDataToSAPErrorAlert: {
                title: 'Error',
            },
        },
        transactionMonitor: {
            title: 'Monitor de Interface SAP',
            download: 'Descargar',
            reSend: 'Re enviar a SAP',
            statusLabel: 'Estado',
            typeLabel: 'Tipo',
            error: '',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} transacciones',
                pagedMessageEmpty: 'Mostrando 0 de 0 transacciones',
                noRows: 'No hay transacciones',
                headers: {
                    guid: 'GUID',
                    status: 'Estado',
                    txnId: 'TXN ID',
                    type: 'Tipo',
                    receiver: 'Pagado a/ Recibido De',
                    detail: 'Detalle',
                    action: 'Acción',
                    creationDate: 'Fecha de Creacion',
                },
                status: {
                    all: 'Todos',
                    received: 'Recibido',
                    sent: 'Enviado',
                    error: 'Error',
                },
            },
            confirmModal: {
                text: '¿Está seguro de querer re enviar las transacciones a SAP?',
            },
        },
        grantingMonitor: {
            title: 'Monitor de Grants',
            download: 'Descargar',
            grantAddresses: 'Grantear Addresses',
            statusLabel: 'Estado',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} addresses',
                pagedMessageEmpty: 'Mostrando 0 de 0 addresses',
                noRows: 'No hay addresses',
                headers: {
                    businessName: 'Razón Social',
                    description: 'Descripción',
                    address: 'Address',
                    nex: 'NEX',
                    jac: 'JAC',
                    grantAddresses: 'Grantear Addresses',
                },
                status: {
                    all: 'Todos',
                    granted: 'Granted',
                    notGranted: 'No Granted',
                },
            },
            successfulModal: {
                addressesGranted: 'Las addresses han sido granteadas correctamente',
                button: 'Continuar',
            },
            errorModal: {
                title: 'Resultados del granting de addresses',
                addressesGrantedSuccessfully:
                    'Addresses granteadas correctamente (%{successTotal})',
                thisErrorHaveAppeared: 'Han aparecido los siguientes errores (%{errorTotal})',
            },
            confirmGrantAddressesModal: {
                text: '¿Está seguro de querer grantear las addresses?',
            },
            grantAddressesErrorAlert: {
                title: 'Error',
            },
        },
        termsAndConditionsModal: {
            title: 'Términos y Condiciones',
            acceptThe: 'Acepto los ',
            termsAndConditions: 'términos y condiciones',
            confirm: 'Confirmar',
            cancel: 'Cancelar',
        },
        success: {
            load: 'Cargado correctamente',
        },
        errors: {
            generic: 'Ha ocurrido un error',
            session: {
                NO_ACTIVE_ACCOUNT: 'No se encontro una cuenta activa',
                WRONG_CREDENTIALS: 'Credenciales inválidas',
                EMAIL_NOT_FOUND: 'No se encontro ningún email',
                NOT_ALLOWED: 'Migración en proceso',
            },
            profile: {
                WRONG_PASSWORD: 'La clave de accesso es incorrecta',
                PASSWORD_MISMATCH: 'Las claves de accesso no coiciden',
            },
        },
        auditor: {
            brcrum: {
                transactions: 'Regresar Transacciones - auditar',
            },
            incident: {
                title: 'Incidente',
                comment: 'Comentario Adicional',
                accept: 'Aceptar',
                deny: 'Rechazar',
                progression: 'Progresión',
                success: 'Exitoso',
                auditHistoryButton: 'Historial de auditoría',
                endContract: 'Fin de Contrato',
                endContractMsg: 'Condición de Fin de Contrato',
                shortComment: 'Comentario muy corto',
                noComment: 'Comentario requerido',
            },
            auditHistoryTable: {
                title: 'Historial auditoría',
                number: 'Número',
                date: 'Fecha',
                drug: 'Droga',
                object: 'Objeto',
                registrator: 'Registrador',
                auditor: 'Auditor',
                status: 'Estado',
                comment: 'Comentario',
            },
        },
        roleAccess: {
            goBack: 'volver',
            accessTable: {
                headers: {
                    roleType: 'Tipo de Rol',
                    id: 'ID',
                    description: 'Descripción',
                    createdBy: 'Creado Por',
                    date: ' Fecha',
                    action: 'Accion',
                },
                noData: ' No Hay Accesos',
            },
            newAccess: {
                addNew: 'Agregar Nuevo',
                selectRole: 'Seleccionar tipo de Rol',
                confirm: 'Confirmar',
                cancel: 'Cancelar',
                successMessage: 'Se ha realizado con èxito',
                TermsAndCondition: {
                    title: 'Autorización a Drogueria',
                    authorize:
                        'Autorizo a la droguería seleccionada de acuerdo a la siguientes condiciones',
                    titlePdf: 'Condiciones Acceso Droguería.',
                },
                userTypes: {
                    fmi: 'FMI',
                    os: 'Obra Social',
                    operator: 'Operador',
                    medic: 'Medico',
                    patient: 'Paciente',
                    auditor: 'Auditor',
                    drugstore: 'Drogueria',
                },
            },
            deleteAccess: {
                title: '¿Está seguro que quiere desautorizar a la Droguería de su elección a cargar a cargar información y evidencia del paciente en la plataforma?',
                option1: 'Si',
                option2: 'No',
                TermsAndCondition: {
                    title: 'Autorización a Drogueria',
                    authorize:
                        'Desautorizo a la droguería seleccionada de acuerdo a las siguientes condiciones',
                    titlePdf: 'Condiciones deshabilitación acceso Drogueria.',
                },
            },
        },
        wallet: {
            walletHeader: {
                totalBalance: 'Balance Total de Nexus',
                sendButton: 'Enviar',
            },
            transactionsList: {
                header: 'Tus recientes',
                from: 'Recibido de',
                to: 'Enviado a',
                seeMore: 'ver más',
                noTransactions: 'No hay transacciones',
            },
            contacts: {
                shipmentDirection: {
                    title: 'Direcciones de envío',
                    search: 'Buscar destinatario',
                    paste: 'Pegar address',
                    send: 'Enviar',
                    addContact: 'Agregar nuevo',
                    deleteContactConfirmation: '¿Está seguro que desea eliminar el contacto?',
                    deleteContactSuccess: 'Contacto eliminado correctamente',
                    titleListContacts: 'Contactos',
                },
            },
            sendNex: {
                title: 'Enviar Nex',
                import: 'IMPORTE',
                typeMoney: 'NEX',
                btnSend: 'Enviar',
                typeCurrency: 'NEX',
                iconSize: {
                    shared: '60',
                },
                typeMsg: {
                    unknownAddressee: 'Destinatario Desconocido',
                    transactionError:
                        'Operación no exitosa. Hubo un error al realizar la transferencia. Intenta de nuevo',
                },
                addContact: 'Agendar Contacto',
                modal: {
                    title: 'Los datos que ingresaste corresponden a',
                    address: 'Address: ',
                    chk: 'Agendar',
                    send: 'Continuar',
                },
            },
            targetTypes: {
                withoutName: 'Destinatario Desconocido',
                withName: 'Destinatorio ',
            },
            transaction: {
                button: {
                    cancel: 'Cancelar',
                    newTry: 'Intenta de nuevo',
                },
                message: {
                    success: 'Transferencia realizada con éxito',
                    error: 'Operación no exitosa. Hubo un error al realizar la transferencia. Intentá de nuevo',
                    loading: 'Transferencia en proceso',
                    viewDetail: 'Ver detalle',
                    invalidValue: 'Valor incorrecto',
                    amountExceeded: 'El monto ingresado supera el importe del saldo',
                    mintBurn: {
                        errorMint: 'No se ha podido realizar la emisión de Nex',
                        errorBurn: 'No se ha podido remover',
                        successMintBurn: 'Se ha realizado con éxito',
                    },
                    addRemoveWhiteListers: {
                        success: 'Se ha realizado con éxito',
                        error: 'No se ha podido realizar la acción',
                    },
                },
            },
            monetaryBase: {
                monetaryBase: 'Base monetaria',
                totalEmitted: 'Total emitido',
                emit: 'Emitir',
                mint: 'Mint',
                burn: 'Burn',
                remove: 'Remover',
                whoCanGrant: 'Quienes tienen permiso para grantear/whitelistear',
                pasteAddress: 'Pegar address',
                add: 'Agregar',
                sendMetaMask:
                    'Accediendo a la interfaz MetaMask. Realiza las acciones correspondientes',
                errorMetaMask: '-',
                installMetaMask: {
                    msg: 'Para poder realizar las operaciones correspondientes a esta pantalla, debes instalar MetaMask',
                    install:
                        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                    btnName: 'Acceder',
                },
            },
            addNew: {
                title: 'Agregar nuevo ',
                name: 'Nombre',
                address: 'Address',
                email: 'Email',
                save: 'Guardar',
                cancel: 'Cancelar',
                errorMsg: 'Por favor completar los siguientes campos:',
            },
            errorCliboard: 'Esta función no esta disponible para este browser',
        },
        bonifications: {
            title: 'Bonificaciones',
            search: 'Buscar...',
            table: {
                pagedMessage: 'Mostrando %{pageStart}-%{pageEnd} de %{total} bonificaciones',
                next: 'Siguiente',
                page: 'Página',
                pagedMessageEmpty: 'Mostrando 0 de 0 bonificaciones',
                noRows: 'No hay bonificaciones',
                headers: {
                    id: 'ID',
                    patientId: 'ID del Paciente',
                    os: 'Obra Social',
                    date: 'Fecha',
                    contract: 'Contrato',
                    product: 'Producto',
                    cicle: 'Nro Ciclo',
                    observations: 'Observaciones',
                    status: 'Estado',
                    action: 'Acción',
                },
                status: {
                    V: 'Validado',
                    E: 'Entregado',
                    R: 'Rechazado',
                    P: 'Pendiente',
                    all: 'Todos',
                },
                buttonTooltips: {
                    edit: 'Editar',
                    delete: 'Borrar',
                },
                emptyObservationMessage: 'Sin observaciones.',
            },
            modal: {
                deliverMessage:
                    '¿Está seguro que quiere marcar %{bonificationQty} bonificaciones como entregado?',
                rejectMessage:
                    '¿Está seguro que quiere marcar %{bonificationQty} bonificaciones como rechazado?',
                observationTitle: 'Observaciones',
            },
            download: 'Descargar',
            deliver: 'Entregar',
            reject: 'Rechazar',
            editObservations: 'Editar Observaciones',
        },
        goBack: 'volver',
        auditIncident: {
            instructions: 'Instrucciones:',
        },
    },
    utils: {
        months: {
            Jan: 'Ene',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Ago',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dic',
        },
    },
};

export default translations;
