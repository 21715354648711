import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';
import Card from '../../../common/components/Card/Card';
import { IndStatusModal } from '../../../indication/components/IndicationsScreen/IndicationsScreen';
import Button from '../../../common/components/Button';
import Input from '../../../common/components/Input';
import CustomSelect from '../../../common/components/CustomSelect';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ListEditor from '../../../common/components/ListEditor/ListEditor';
import Checkbox from '../../../common/components/Checkbox';
import { getDate, dateStr, todayStr } from '../../../utils/date';
import './EditPatientScreen.scss';
import '../../../common/components/Input/Input.scss';
import '../../../indication/components/Table/table.css';
import { encryptString, getSharedSecret } from '../../../utils/encryption';
import { KeyManager } from 'jasper-roche-crypto';
import BackLink from '../../../common/components/BackLink/BackLink';
import { UserTypes } from '../../../constants';
import { checkDuplicatedDNIPatient } from './utils';

const _actual = (c) => c?.contrato_version?.find((v) => v.id === c.version_actual) ?? {};

const ContractLink = ({
    children,
    contracts,
    setContracts,
    addableContracts,
    getContractOs,
    privateKey,
    publicKey,
    patient,
}) => {
    const [show, setshow] = useState(false);
    const _id = (contracts?.reduce((max, c) => (c._id > max ? c._id : max), 0) ?? 0) + 1;
    const isEditing = patient.contracts.length > 0 ?? false;

    // TODO check if filter is necessary
    // addableContracts = addableContracts.filter(x => x.estado_version === 'A')
    const currentProducts = contracts?.reduce((acc, contract) => {
        const _prods =
            _actual(addableContracts?.find((_c) => _c.id === contract.contrato))
                ?.contrato_v_beneficio ?? [];

        for (const p of _prods) acc.add(p.producto);

        return acc;
    }, new Set());

    const getContractKey = (isSurType, res, contract) => {
        let keyB = '';
        let encryptedPK = '';

        if (!isSurType) {
            keyB = getSharedSecret(
                privateKey,
                publicKey,
                res.contrato_version?.find((v) => v.id === contract.version_actual)
                    ?.grupo_auditor_pubkey,
            );
            encryptedPK = encryptString(privateKey, keyB);
        }

        return encryptedPK;
    };

    return (
        <div className='expandable-link'>
            {show && (
                <div className='expandable-container'>
                    <table className='GreyTable coloreven addcontractstable'>
                        <thead>
                            <tr>
                                <th>{I18n.t('patient.editPatient.contractCard.id')}</th>
                                <th>{I18n.t('patient.editPatient.contractCard.desc')}</th>
                                <th>{I18n.t('patient.editPatient.contractCard.type')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addableContracts?.map((contract, index) => {
                                const isInContracts = isEditing
                                    ? contracts?.find((c) => c.contrato === contract.id) ||
                                      contract.habilitado === false
                                    : contract.habilitado === false ||
                                      contracts?.find((c) => c.contrato === contract.id);
                                const contractProducts = _actual(
                                    addableContracts?.find((c) => c.id === contract.id),
                                )?.contrato_v_beneficio?.map((p) => p.producto);

                                const hasDupProduct = contractProducts?.some((p) =>
                                    currentProducts.has(p),
                                );

                                return (
                                    <tr
                                        key={index}
                                        onClick={() => {
                                            //TODO: Required a refactor
                                            if (isInContracts || hasDupProduct) return;
                                            // llama a la api de contrato para traer el objeto completo
                                            // y al responder lo carga en la tabla del paciente con la evidencia
                                            getContractOs(contract.id, (res) => {
                                                const isSurType = res.tipo_contrato_tipo === 'S';

                                                const encryptedPK = getContractKey(
                                                    isSurType,
                                                    res,
                                                    contract,
                                                );
                                                const currentVersion = res.contrato_version.find(
                                                    (v) => v.id === contract.version_actual,
                                                );

                                                // helper function to attach new args to common ones
                                                // with sur type and non sur type
                                                const getArgs = (newContractArgs) => [
                                                    ...(contracts ?? []),
                                                    {
                                                        activo: true,
                                                        value: contract.id,
                                                        contrato: contract.id,
                                                        id: undefined,
                                                        _id,
                                                        descripcion: contract.descripcion,
                                                        tipo_contrato: contract.tipo_contrato,
                                                        tipo_contrato_tipo:
                                                            contract.tipo_contrato_tipo,
                                                        descripcion_tipo:
                                                            contract.tipo_contrato_descr,
                                                        subtipo: contract.indicacion_descr,
                                                        indicacion_descr: contract.indicacion_descr,
                                                        ...newContractArgs,
                                                    },
                                                ];

                                                if (isSurType) {
                                                    setContracts(
                                                        getArgs({
                                                            evidencia:
                                                                currentVersion?.contrato_v_evidencia?.map(
                                                                    (e) => ({
                                                                        ...e,
                                                                        id: undefined,
                                                                        requerida: true,
                                                                        adjunto: [],
                                                                    }),
                                                                ),
                                                            auditGroup:
                                                                currentVersion?.grupo_auditor,
                                                        }),
                                                    );
                                                } else {
                                                    setContracts(
                                                        getArgs({
                                                            encrypted_pk_in: encryptedPK,
                                                            evidencia: res.contrato_version
                                                                .find(
                                                                    (v) =>
                                                                        v.id ===
                                                                        contract.version_actual,
                                                                )
                                                                ?.contrato_v_evidencia?.map(
                                                                    (e) => ({
                                                                        ...e,
                                                                        id: undefined,
                                                                        requerida: true,
                                                                        adjunto: [],
                                                                    }),
                                                                ),
                                                            paciente_audit_enc: {
                                                                nombre_enc: '',
                                                                dni_enc: '',
                                                                afiliado_enc: '',
                                                                fecha_nacimiento_enc: '',
                                                            },
                                                            pubkey: currentVersion?.grupo_auditor_pubkey_data,
                                                            auditGroup:
                                                                currentVersion?.grupo_auditor,
                                                        }),
                                                    );
                                                }

                                                setshow(false);
                                            });
                                        }}
                                        className={
                                            (isInContracts ? 'disabled ' : '') +
                                            (hasDupProduct ? 'disabled repeated ' : '')
                                        }
                                    >
                                        <td>{contract.id}</td>
                                        <td>{contract.descripcion}</td>
                                        <td>{contract.tipo_contrato_descr}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            <div
                className='card-rlink'
                onClick={() => {
                    setshow(!show);
                    /*setContracts([
                        ...contracts??[],
                        {
                            // max _id in contracts
                            _id: contracts?.reduce( (max, c) => (
                                c._id>max?c._id:max
                            ), 0 )+1,
                        }
                    ])*/
                }}
            >
                {children}
            </div>
        </div>
    );
};

export const ContractsTable = ({
    contracts,
    setContracts,
    setContractSelected,
    getProgramedDose,
    getAuditorProgramedDose,
    userType,
    lastWill,
    getFileToS3,
    active,
}) => {
    const history = useHistory();
    const [showEvidence, setShowEvidence] = useState(false);

    useEffect(() => {
        if (showEvidence) {
            history.push('/patients/evidence');
            setShowEvidence(false);
        }
    }, [showEvidence]);

    const getFilesS3 = async (contract) => {
        await Promise.all(
            contract.evidencia.map(async (ev) => {
                await Promise.all(
                    ev.adjunto.map(async (adj) => {
                        if (adj.tipo_adjunto === 'A') {
                            let s3Base64 = {};
                            let errorFile = false;
                            await getFileToS3(adj.download_url)
                                .then((x) => (s3Base64 = x))
                                .catch((error) => (errorFile = true));
                            const s3File = new File([s3Base64], adj.contenido);
                            adj._file = s3File;
                            adj.error = errorFile;
                        }
                    }),
                );
            }),
        );
        setShowEvidence(true);
    };

    const loadEvidences = async (contract) => {
        if (!contract.id) return;
        lastWill && lastWill();
        // pedir files
        if (contract.evidencia.some((x) => x.adjunto?.length > 0)) {
            await getFilesS3(contract);
        } else {
            setShowEvidence(true);
        }
        setContractSelected(contract);
    };

    return (
        <table className='DefTable'>
            <thead>
                <tr>
                    <th>{I18n.t('patient.editPatient.contractCard.id')}</th>
                    <th>{I18n.t('patient.editPatient.contractCard.desc')}</th>
                    <th>{I18n.t('patient.editPatient.contractCard.type')}</th>
                    <th>{I18n.t('patient.editPatient.contractCard.evReq')}</th>
                    <th>{I18n.t('patient.editPatient.contractCard.progDose')}</th>
                    <th>{I18n.t('patient.editPatient.contractCard.act_inact')}</th>
                </tr>
            </thead>
            <tbody>
                {contracts?.map((contract, index) => {
                    // FIXME : notifications are harcoded
                    const pendingEv = contract.evidencia?.filter(
                        (x) =>
                            (!x.adjunto || x.adjunto.length === 0) &&
                            x.aplicacion <= contract.ciclo_actual,
                    )?.length;
                    return (
                        <tr key={index}>
                            <td>{contract._id}</td>
                            <td>{contract.descripcion}</td>
                            <td>{contract.descripcion_tipo}</td>
                            <td>
                                <i
                                    onClick={() => {
                                        contract.evidencia && loadEvidences(contract);
                                    }}
                                    className='icon-doc ic-spe'
                                >
                                    <i className='icon-magnifier ic-sub' />
                                    {contract.evidencias_notificar && contract.activo && active ? (
                                        <div className='ic-notif'>{pendingEv}</div>
                                    ) : null}
                                </i>
                            </td>
                            <td>
                                <i
                                    onClick={() => {
                                        if (!contract.id) return;
                                        lastWill && lastWill();
                                        setContractSelected(contract);
                                        if (userType === 'AUDITOR') {
                                            getAuditorProgramedDose(contract.id);
                                        } else {
                                            getProgramedDose(contract.id);
                                        }
                                        history.push('/patients/doses');
                                    }}
                                    className={
                                        'icon-magnifier-add ic-spe ' +
                                        (!contract.id ? 'disabled' : '')
                                    }
                                >
                                    {contract.dosis_notificar >= 1 && contract.activo && active ? (
                                        <div className='ic-notif'>{contract.dosis_notificar}</div>
                                    ) : null}
                                </i>
                            </td>
                            <td>
                                <Checkbox
                                    id={index + 'status'}
                                    className='pill-toggle small centered'
                                    checked={contract.activo}
                                    onChange={(v) => {
                                        contract.activo = !contract.activo;
                                        setContracts(contracts);
                                    }}
                                    disabled={userType === UserTypes.DRUGSTORE}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const Patient = ({
    patient,
    updPatient,
    showErrors,
    sexError,
    dniError,
    affiliateError,
    readOnly,
}) => {
    const history = useHistory();
    const updPatDate = (obj) => {
        Object.keys(obj).map((key) => {
            obj[key] = dateStr(obj[key]);
        });
        updPatient && updPatient(obj);
    };

    const sexes = ['N', 'M', 'F'];

    return (
        <>
            <div className='card-row'>
                <div className='cb-mt'>
                    <Input
                        className='white'
                        label={I18n.t('patient.editPatient.patientCard.dni')}
                        value={patient.dni}
                        handleChange={(v) => updPatient && updPatient({ dni: v })}
                        error={dniError}
                        mask='99.999.999'
                        disabled={readOnly}
                    />
                </div>
                <div className='cb-mt'>
                    <Input
                        className='white'
                        label={I18n.t('patient.editPatient.patientCard.nya')}
                        value={patient.name}
                        handleChange={(v) => updPatient && updPatient({ name: v })}
                        error={showErrors && !patient.name}
                        disabled={readOnly}
                    />
                </div>
                <div className='cb-mt'>
                    <Input
                        className='white'
                        label={I18n.t('patient.editPatient.patientCard.affiliate')}
                        value={patient.affiliate}
                        handleChange={(v) => updPatient && updPatient({ affiliate: v })}
                        error={affiliateError}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className='card-row'>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label'>
                            {I18n.t('patient.editPatient.patientCard.sex')}
                        </label>
                    </div>
                    <CustomSelect
                        options={sexes
                            .map((s) => ({
                                value: s,
                                label: I18n.t('patient.editPatient.patientCard.sexes.' + s),
                            }))
                            .sort((a, b) =>
                                a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                            )}
                        placeholder='-'
                        value={patient.sex}
                        onSelect={(v) => updPatient && updPatient({ sex: v })}
                        className='rounded i-screen'
                        error={sexError && !patient.sex}
                        disabled={readOnly}
                    />
                </div>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label '>
                            {I18n.t('patient.editPatient.patientCard.birthDate')}
                        </label>
                    </div>
                    <DatePicker
                        selected={getDate(patient?.birthDate)}
                        dateFormat='dd/MM/yyyy'
                        onChange={(v) => updPatDate({ birthDate: v })}
                        placeholderText={moment().format('DD/MM/YYYY')}
                        autoComplete='off'
                        maxDate={new Date()}
                        disabled={readOnly}
                    />
                </div>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label'>
                            {I18n.t('patient.editPatient.patientCard.weight')}
                        </label>
                    </div>
                    <div className='flex-row'>
                        <ListEditor
                            list={patient.weight}
                            setList={(v) =>
                                updPatient &&
                                updPatient({ weight: v.filter((e) => e.peso || e.effdt) })
                            }
                            attributes={[
                                {
                                    label: I18n.t('patient.editPatient.patientCard.date'),
                                    key: 'effdt',
                                    i_props: {
                                        type: 'date',
                                        required: true,
                                    },
                                },
                                {
                                    label: I18n.t('patient.editPatient.patientCard.weight'),
                                    key: 'peso',
                                    i_props: { type: 'number', required: true },
                                },
                            ]}
                            primaryKey='peso'
                            defaultElement={{
                                peso: undefined,
                                effdt: todayStr,
                                _LiEd_Editable: true,
                            }}
                            editable={readOnly ? true : 'last'}
                            addMessage={'+ ' + I18n.t('patient.editPatient.patientCard.addWeight')}
                        />
                        <div className='unit'>kg</div>
                    </div>
                </div>
                <div className='cb-mt'>
                    <div key='input-label'>
                        <label className='input-label'>
                            {patient.status === 'active'
                                ? I18n.t('patient.editPatient.patientCard.active')
                                : I18n.t('patient.editPatient.patientCard.inactive')}
                        </label>
                    </div>
                    <Checkbox
                        id='status'
                        className='pill-toggle'
                        checked={patient.status === 'active'}
                        onChange={(v) =>
                            updPatient &&
                            updPatient({
                                status: patient.status !== 'active' ? 'active' : 'inactive',
                            })
                        }
                        disabled={readOnly}
                    />
                </div>
                {patient.accesos && !readOnly && (
                    <div className='cb-mt'>
                        <div className='align-button'>
                            <Button
                                text={I18n.t('patient.editPatient.patientCard.access')}
                                className='primary small'
                                onClick={() => history.push('access')}
                            >
                                {I18n.t('patient.editPatient.patientCard.access')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const EditPatientScreen = ({
    patientDetails,
    loadPatientData,
    editPatientWithRollback, // edit patient that includes add/remove accesses
    postPatient,
    editPatientStatus,
    editPatientWithRollbackStatus,
    username,
    osId,

    contracts,
    getOSContracts,
    setContractSelected,
    getProgramedDose,
    getAuditorProgramedDose,
    userType,
    setBreadcrumbs,

    getContractOs,
    getFileToS3,

    patients,
}) => {
    const [showState, setShowState] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [sexError, setSexError] = useState(false);
    const [dniError, setDniError] = useState(false);
    const [affiliateError, setAffiliateError] = useState(false);
    const [patient, setPatient] = useState({
        ...(patientDetails ?? {}),
        contracts:
            patientDetails?.contracts?.map((c) => ({
                ...c,
                _id: c.id ?? c._id,
                label: c.descripcion,
                value: c.contrato,
            })) ?? [],
    });
    /* save on first render patientDetails, used
     to rollback editPatientWithRollback */
    const [oldPatientData] = useState(patient);
    const keyManager = new KeyManager(username);
    const privateKey = keyManager.deriveDataKey();
    const publicKey = keyManager.getPublicKey(privateKey);

    // indication save statuses
    // null | 'NEW' | 'EDIT'
    // 'NEW' -> editPatientStatus
    // 'EDIT' -> editPatientWithRollbackStatus
    const [submitStatus, setSubmitStatus] = useState(null);

    const readOnly = userType === UserTypes.DRUGSTORE;

    useEffect(() => {
        setPatient({
            ...(patientDetails ?? {}),
            contracts: patientDetails?.contracts
                ? patientDetails?.contracts?.map((c) => ({
                      ...c,
                      _id: c.id,
                      label: c.descripcion,
                      value: c.contrato,
                  }))
                : [],
        });
    }, [patientDetails]);

    // Utility functions to update patient atributtes
    const updPatient = (obj) => {
        return setPatient({ ...patient, ...obj });
    };

    useEffect(() => {
        // Get OS Contracts
        if (userType !== UserTypes.DRUGSTORE) getOSContracts(true);

        // Set breadcrumbs
        setBreadcrumbs([{ link: '/patients/edit', label: I18n.t('editPatient.brcm.title') }]);
    }, []);

    // Load OS contract data into patient contracts
    useEffect(() => {
        const loadedContracts = patient.contracts?.map((c) => {
            const contract = contracts?.find((osc) => osc.id === c.contrato) ?? {};
            return {
                ...c,
                tipo_contrato: contract.tipo_contrato,
                subtipo: contract.indicacion_descr,
                indicacion_descr: contract.indicacion_descr,
                id: c.id,
            };
        });
        updPatient({ contracts: loadedContracts });
    }, [contracts]);

    useEffect(() => {
        const parsed = patient.dni?.replace(/[._]/g, '');
        if (!patient.id) {
            if (parsed?.length !== 8) setDniError(true);
            else setDniError(false);
        } else {
            if ((parsed.length > 0 && parsed.length < 7) || (!patient.dni && patient.id))
                setDniError(true);
            else setDniError(false);
        }
    }, [patient]);

    // Function to call before changing page
    const lastWill = () => loadPatientData(patient);

    // use in creating new patient, access still goes in the payload
    const getAccessContractsAccess = (patient) => {
        if (contracts) {
            return patient.contracts
                .filter((contract) => {
                    const contractFound = contracts.find((c) => c.id === contract.contrato);
                    return (
                        contractFound?.grupo_auditor_pubkey_data && contractFound?.grupo_auditor_id
                    );
                }) //Sur contract
                .map((contract) => {
                    const contractInfo = contracts.find((c) => c.id === contract.contrato);
                    return {
                        publicKey: contractInfo.grupo_auditor_pubkey_data,
                        owner: false,
                        grupo_auditor: contractInfo.grupo_auditor_id,
                    };
                });
        } else return [];
    };

    const getNewAccessesData = (patient) => {
        let result = [];
        if (contracts) {
            result = patient.contracts
                .filter((contract) => contract.tipo_contrato_tipo !== 'S') //Sur contract
                .map((contract) => {
                    const contractInfo = contracts.find((c) => c.id === contract.contrato);
                    return {
                        paciente: patient.id,
                        publicKey: contractInfo.grupo_auditor_pubkey_data,
                        owner: false,
                        grupo_auditor: contractInfo.grupo_auditor_id,
                        tipo_usuario: 'U', // always grupo_auditor
                    };
                });
        }
        return result;
    };

    const filterPatientAccess = (patientAccess) => {
        //control about auditor access duplicate
        const idAuditor = new Set();

        const accessesWithoutDuplicates = patientAccess
            .filter((access) => {
                const foundAuditor = patient.accesos.find(
                    (a) => a.grupo_auditor === access.grupo_auditor,
                );
                const foundAccess = patient.accesos.find((a) => a.id === access.id);
                return !foundAccess && !foundAuditor;
            })
            .filter(({ grupo_auditor, drogueria }) => {
                if (idAuditor.has(grupo_auditor) && grupo_auditor !== undefined && !drogueria)
                    return false;

                idAuditor.add(grupo_auditor);
                return true;
            });

        return accessesWithoutDuplicates;
    };

    const getIndicationModalStatus = () => {
        if (dniError) return { error: true, message: I18n.t('patient.newPatient.duplicateDni') };
        if (affiliateError)
            return { error: true, message: I18n.t('patient.newPatient.duplicateAffiliate') };
        if (submitStatus === 'NEW') return editPatientStatus;
        if (submitStatus === 'EDIT') return editPatientWithRollbackStatus;
    };

    return (
        <div className='i-container'>
            <BackLink to='/patients' className='margin-bottom' />
            <Card
                title={I18n.t('patient.editPatient.patientCard.title')}
                detail={
                    I18n.t('patient.editPatient.patientCard.id') +
                    ': ' +
                    (patient.id ? patient.id : '--')
                }
                className='side-padding-l'
            >
                <Patient
                    patient={patient}
                    updPatient={updPatient}
                    showErrors={showErrors}
                    sexError={sexError}
                    dniError={dniError}
                    affiliateError={affiliateError}
                    readOnly={readOnly}
                />
            </Card>
            <Card title={I18n.t('patient.editPatient.contractCard.title')}>
                <br />
                <ContractsTable
                    contracts={patient.contracts}
                    setContracts={(v) => updPatient({ contracts: v })}
                    setContractSelected={setContractSelected}
                    getProgramedDose={getProgramedDose}
                    lastWill={lastWill}
                    getFileToS3={getFileToS3}
                    userType={userType}
                    getAuditorProgramedDose={getAuditorProgramedDose}
                    active={patient.status !== 'inactive'}
                />
                <br />
                <br />
                {!readOnly && (
                    <ContractLink
                        contracts={patient?.contracts}
                        setContracts={(contracts, publicKey, groupId) => {
                            return updPatient({
                                contracts: contracts,
                            });
                        }}
                        addableContracts={contracts}
                        getContractOs={getContractOs}
                        privateKey={privateKey}
                        publicKey={publicKey}
                        patient={patient}
                    >
                        + {I18n.t('patient.editPatient.contractCard.addNew')}
                    </ContractLink>
                )}
                <br />
            </Card>

            {patient?.id ? (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.save')}
                    onClick={() => {
                        setSubmitStatus('EDIT');
                        setShowErrors(true);
                        if (!patient.sex) setSexError(true);
                        if (!dniError && patient.sex && patient.name) {
                            if (checkDuplicatedDNIPatient(patient, patients, true)) {
                                setShowState(true);
                                setDniError(true);
                                return;
                            }

                            if (
                                patients.some(
                                    (p) =>
                                        p.affiliate === patient.affiliate.replace(/\./g, '') &&
                                        p.id !== patient.id,
                                )
                            ) {
                                setShowState(true);
                                setAffiliateError(true);
                                return;
                            }

                            const patientAccess = patient.accesos.concat(
                                getNewAccessesData(patient).filter((acc) => !!acc?.publicKey),
                            );
                            const newAccesses = filterPatientAccess(patientAccess);
                            editPatientWithRollback(
                                oldPatientData,
                                patient.id,
                                {
                                    ...patient,
                                    accesos: undefined,
                                    weight: patient.weight?.filter((e) => e.peso && e.effdt) ?? [],
                                },
                                newAccesses,
                            );
                            setShowState(true);
                            setDniError(false);
                            setAffiliateError(false);
                        }
                    }}
                    disabled={readOnly}
                />
            ) : (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.new')}
                    onClick={() => {
                        setSubmitStatus('NEW');
                        setShowErrors(true);

                        if (!patient.sex) setSexError(true);
                        if (!patient.dni) setDniError(true);
                        if (!patient.affiliate) setAffiliateError(true);
                        else if (
                            !dniError &&
                            patient.affiliate &&
                            patient.sex &&
                            patient.name &&
                            patient.affiliate
                        ) {
                            if (checkDuplicatedDNIPatient(patient, patients)) {
                                setDniError(true);
                                setShowState(true);
                                return;
                            }

                            if (
                                patients.some(
                                    (p) => p.affiliate === patient?.affiliate?.replace(/\./g, ''),
                                )
                            ) {
                                setAffiliateError(true);
                                setShowState(true);
                                return;
                            }
                            postPatient({
                                ...patient,
                                dni: patient.dni?.replaceAll(/[._]/g, ''),
                                contracts: patient.contracts ?? [],
                                weight: patient.weight?.filter((e) => e.peso && e.effdt) ?? [],
                                accesos: [
                                    {
                                        obra_social: osId,
                                        owner: true,
                                        publicKey: publicKey,
                                    },
                                ].concat(getAccessContractsAccess(patient)),
                            });
                            setShowState(true);
                            setDniError(false);
                            setAffiliateError(false);
                        }
                    }}
                />
            )}
            <IndStatusModal
                setShow={setShowState}
                show={
                    showState
                    // && {message: editPatientStatus.error? editPatientStatus?.errorMessage: undefined} //TODO:
                }
                status={getIndicationModalStatus()}
                setShowErrors={setShowErrors}
                onClick={() => setSubmitStatus(null)}
            />
        </div>
    );
};

export default EditPatientScreen;
